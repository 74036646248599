<template>
  <div>
    <b-button-group class="mb-1">
      <b-button
        :variant="!+lang ? 'primary' : 'outline-primary'"
        to="/admin/categories/0"
        >عربي</b-button
      >
      <b-button
        to="/admin/categories/1"
        :variant="!+lang ? 'outline-primary' : 'primary'"
        >English</b-button
      >
    </b-button-group>
    <b-row>
      <b-col cols="12" :lg="show ? 6 : 12" style=" transition: all 0.8s;">
        <b-card no-body>
          <b-card-body class="px-0">
            <vue-good-table
              ref="customers-table"
              :columns="columns"
              :rows="activeCategoriesList"
              :rtl="false"
              :small="true"
              styleClass="vgt-table condensed"
              :select-options="{
                enabled: false,
                disableSelectInfo: true,
                selectOnCheckboxOnly: true
              }"
              :row-style-class="rowStyleClassFn"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'action'">
                  <b-button
                    @click="actionShow() & getSubCategories(props.row)"
                    size="sm"
                    variant="flat-secondary"
                    class="btn-icon rounded-circle"
                    style="padding: 2px 6px !important; margin: auto;"
                  >
                    <unicon name="ellipsis-v" width="18"></unicon>
                  </b-button>
                </span>
                <span
                  v-else-if="props.column.field == 'dateCreated'"
                >
                  {{
                    new Date(
                      props.formattedRow[props.column.field]
                    ).toLocaleDateString("en-GB")
                  }}
                </span>
                <span v-else-if="props.column.field == 'imageDto'">
                  <img
                    class="rounded-circle"
                    :src="
                      props.row.imageDto.path.replace(
                        '~',
                        $store.getters['app/domainHost']
                      )
                    "
                    width="50"
                    height="50"
                  />
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </b-card-body>
        </b-card>
        <b-col cols="12" class="d-flex justify-content-center">
          <b-pagination
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
            :value="DataFirstPageCategories"
            :total-rows="filterdDataListCategories.length"
            :per-page="DataPageLengthCategories"
            @input="(value) => updateDataPaginationCategories(value)"
          >
            <template #prev-text>
              <unicon width="20" name="angle-right" fill="#A41F02" />
            </template>
            <template #next-text>
              <unicon width="20" name="angle-left" fill="#A41F02" />
            </template>
          </b-pagination>
        </b-col>
      </b-col>
      <transition name="fade">
        <b-col cols="12" lg="6" v-if="show">
          <b-card no-body class="overflow-hidden">
            <b-card-header class="p-0  bg-light">
              <b-button
                size="sm"
                variant="flat-secondary"
                class="btn-icon rounded-circle"
                style="padding: .5rem 10px!important"
                :disabled="isNotSelectedRows"
              >
                <unicon name="trash-alt" width="18"></unicon>
              </b-button>
              <div class="d-flex">
                <b-button
                  size="sm"
                  variant="flat-secondary"
                  class="btn-icon rounded-circle"
                  style="padding: .5rem 10px!important"
                  @click="
                    actionNonShow() &
                    setIsCreateCategorieModal(true) &
                    resetCategoriesDto(activeCategoryId)
                  "
                >
                  <unicon name="file-info-alt" width="18"></unicon>
                </b-button>
                <b-button
                  size="sm"
                  variant="flat-secondary"
                  class="btn-icon rounded-circle"
                  style="padding: .5rem 10px!important"
                  @click="actionNonShow()"
                >
                  <unicon name="times" width="18"></unicon>
                </b-button>
              </div>
            </b-card-header>
            <b-card-body class="px-0">
              <vue-good-table
                ref="sub_category_table"
                :columns="columnsSubCategoriesList"
                :rows="activeSubCategoriesList"
                :rtl="false"
                :small="true"
                styleClass="vgt-table condensed"
                :select-options="{
                  enabled: true,
                  disableSelectInfo: true,
                  selectOnCheckboxOnly: true
                }"
                @on-selected-rows-change="selectionChanged"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'action'">
                    <b-button
                      size="sm"
                      variant="flat-secondary"
                      class="btn-icon rounded-circle"
                      style="padding: 2px 6px !important; margin: auto;"
                      @click="
                        resetCategoriesDto(props.row.id) &
                          setIsCreateCategorieModal(true)
                      "
                    >
                      <unicon name="ellipsis-v" width="18"></unicon>
                    </b-button>
                  </span>
                  <span v-else-if="props.column.field == 'dateCreated'">
                    {{
                      new Date(
                        props.formattedRow[props.column.field]
                      ).toLocaleDateString("en-GB")
                    }}
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
              </vue-good-table>
            </b-card-body>
          </b-card>
          <b-col cols="12" class="d-flex justify-content-center">
            <b-pagination
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              :value="DataFirstPageSubCategories"
              :total-rows="filterdDataListSubCategories.length"
              :per-page="DataPageLengthSubCategories"
              @input="value => updateSubDataPagination(value)"
            >
              <template #prev-text>
                <unicon width="20" name="angle-right" fill="#A41F02" />
              </template>
              <template #next-text>
                <unicon width="20" name="angle-left" fill="#A41F02" />
              </template>
            </b-pagination>
          </b-col>
        </b-col>
      </transition>
    </b-row>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from "vuex";
  import { VueGoodTable } from "vue-good-table";
  export default {
    props: {
      lang: String,
    },
    components: {
      VueGoodTable,
    },
    data() {
      return {
        isNotSelectedRows: true,
        columns: [
          {
            label: "صورة ",
            field: "imageDto",
          },
          {
            label: "التصنيفات الرئيسية",
            field: "name",
          },
          {
            label: "التصنيفات الفرعية",
            field: "subCategoriesCount",
          },
          {
            label: "تاريخ الإضافة",
            field: "dateCreated",
          },
          {
            label: "details",
            field: "action",
            sortable: false,
          },
        ],
        columnsSubCategoriesList: [
          {
            label: "التصنيفات الفرعية",
            field: "name",
          },
          {
            label: "تاريخ الإضافة",
            field: "dateCreated",
          },
          {
            label: "details",
            field: "action",
            sortable: false,
          },
        ],
      };
    },
    computed: {
      ...mapGetters([
        "categoryDto",
        "activeCategoryId",
        "show",

        "categoriesList",
        "activeCategoriesList",
        "filterdDataListCategories",
        "DataFirstPageCategories",
        "DataPageLengthCategories",

        "activeSubCategoriesList",
        "filterdDataListSubCategories",
        "DataFirstPageSubCategories",
        "DataPageLengthSubCategories",

        "categoryFilter",
        "subCategoryFilter"
      ]),
    },
    created() {
      this.getCategories(!+this.lang ? 1 : 2);
    },
    methods: {
      ...mapActions([
        "getCategories",
        "getSubCategories",
        "actionShow",
        "actionNonShow",
        "updateCategories",
        "setIsCreateCategorieModal",
        "resetCategoriesDto",
        "updateDataPaginationCategories",
        "updateSubDataPagination"
      ]),
      selectionChanged() {
        this.isNotSelectedRows = !this.$refs["customers-table"].selectedRows
          .length;
      },
      rowStyleClassFn(row) {
          return row.id == this.activeCategoryId ? 'active-categry' : '';
      },
      getSelectedRow() {
        let rowIds = this.$refs["sub_category_table"].selectedRows.map(
          (row) => row.id
        );
        return rowIds;
      },
    },
    watch: {
      async lang(lang) {
        this.categoryFilter.search = ""
        this.subCategoryFilter.search = ""
        await this.getCategories(!+lang ? 1 : 2);
        if (this.activeCategoryId > 0) {
          const cat = this.categoriesList
            .find(item => (item.id == this.activeCategoryId
                        || item.linkId == this.activeCategoryId))
          this.getSubCategories(cat)
        }
      }
    },
  };
</script>
<style lang="scss">
@import "@/assets/scss/variables/variables.scss";
.active-categry {
  background: rgba($primary, 70%);
  td {
    color: #fff!important;
  }
}
</style>